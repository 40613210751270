<template>
<!-- begin::kb-header -->
  <header class="kb-header" id="kb-header">
    <div class="header-component">
      <!-- header-title -->
      <div class="header-column header-title">
        생활연수 신청
      </div>
      <!-- //header-title -->
    </div>
  </header>
  <!-- end::kb-header -->

  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-facility">
    <!-- main-header -->
    <div class="main-header main-header-visual">
      <!-- ㅡmain-header-nav -->
      <div class="header-snb">
        <nav class="snb">
          <ul class="snb-list">
            <li class="snb-item">
              <button :class="{'is-active': reserveVenue === '연수원'}"
                      @click="reserveVenue = '연수원'"
              >연수원</button>
            </li>
            <li class="snb-item">
              <button :class="{'is-active': reserveVenue === '체육시설'}"
                      @click="reserveVenue = '체육시설'"
              >체육시설</button>
            </li>
            <!--
            <li class="snb-item">
              <button :class="{'is-active': reserveVenue === '스튜디오'}"
                      @click="reserveVenue = '스튜디오'"
              >스튜디오</button>
            </li>
            -->
          </ul>
        </nav>
      </div>
    </div>
    <!-- //main-header -->
    <!-- main-content -->
    <div class="main-content">

      <!-- contnet-section:공지사항 -->
      <NoticeMobileComponent/>

      <!-- contnet-section:신청현황 -->
      <HistoryMobileComponent v-if="reserveVenue === '연수원'"/>
      <HistorySportsMobileComponent v-if="reserveVenue === '체육시설'"/>

      <!-- contnet-section:학습 바로가기 -->
      <DirectLinkMobileComponent/>

      <div class="page-buttons">
        <button class="kb-btn kb-btn-primary" @click="goApply"><span class="text">{{ reserveVenue }} 이용신청</span></button>
      </div>
    </div>
    <!-- //main-content -->
  </main>
  <!-- end::kb-main -->
</template>

<script>
import {computed, ref} from 'vue';
import {useStore} from "vuex";
import {
  ACT_INIT_FCLT_APLY_MOBILE_INFO,
  ACT_INIT_TRNCT_APLY_MOBILE_INFO,
} from "@/store/modules/trnct/trnct";

import NoticeMobileComponent from "@/components/daily/mobile/NoticeMobileComponent";
import HistoryMobileComponent from "@/components/daily/mobile/HistoryMobileComponent";
import HistorySportsMobileComponent from "@/components/daily/mobile/HistorySportsMobileComponent";
import DirectLinkMobileComponent from "@/components/daily/mobile/DirectLinkMobileComponent";
import {useRouter} from "vue-router";

export default {
  name: 'DailyMobileApply',
  components: {
    NoticeMobileComponent,
    HistoryMobileComponent,
    HistorySportsMobileComponent,
    DirectLinkMobileComponent,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const session = computed(() => store.state.auth.session);

    const reserveVenue = ref('연수원');

    const goApply = () => {
      if(reserveVenue.value == '연수원'){
        store.commit(`trnct/${ACT_INIT_TRNCT_APLY_MOBILE_INFO}`);
        router.push('/daily/apply/step0');
      }else{
        store.commit(`trnct/${ACT_INIT_FCLT_APLY_MOBILE_INFO}`);
        router.push('/daily/apply/sports/step0');
      }
    }

    return {
      reserveVenue,
      session,
      goApply
    };
  },
};
</script>
