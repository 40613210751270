<template>
  <article class="content-section section-panel section-status">
    <div class="section-header">
      <h3 class="title">신청현황</h3>
    </div>
    <div class="status-top">
      <div class="top-title">전체 {{items.length}}건</div>
      <div class="top-buttons">
        <router-link :to="{path: '/daily/history'}" class="kb-btn kb-btn-secondary"><span class="text">신청내역 바로가기</span></router-link>
      </div>
    </div>
    <!-- text-item-container -->
    <div class="text-item-container">
      <!-- text-item -->
      <div v-for="(item) in items" :key="item.trnctAplySn" class="text-item">
        <h5 class="item-title">연수원 <span class="title-status text-primary">{{ item.stbyYn == 'Y' ? `대기(순번 : ${item.stbySeq})` : '신청완료'}}</span></h5>
        <h6 class="item-subtitle">{{ item.trnctNm }}</h6>
        <div class="item-row">
          <span class="text-title">신청기간</span>
          <span class="text-description">{{ item.rsvtAplyDd }}-{{ item.rsvtAplyDdEnd }}</span>
        </div>
        <div class="item-row">
          <span class="text-title">신청일시</span>
          <span class="text-description">{{ item.regDt }}</span>
        </div>
        <div class="item-row row-buttons" v-if="checkThreeDay(item)">
          <button class="kb-btn kb-btn-outline" @click="detailTrcntAply(item)"><span class="text">상세 ・ 수정</span></button>
          <button class="kb-btn kb-btn-outline" @click="openCancelPop(item)"><span class="text">취소</span></button>
        </div>
        <div v-else-if="new Date() > new Date(item.rsvtAplyDd) "  class="item-row">
          <span>-</span>
        </div>
        <div class="item-row row-buttons" v-else>
          <button class="kb-btn kb-btn-outline" @click="alertThreeday"><span class="text">상세 ・ 수정</span></button>
          <button class="kb-btn kb-btn-outline" @click="alertThreeday"><span class="text">취소</span></button>
        </div>
      </div>
    </div>
    <!-- //text-item-container -->
  </article>

  <div class="kb-alert-container" :class="{'is-active' : isCancelPop}">
    <div class="kb-alert">
      <div class="kb-alert-contents">
        <div class="title-area">
          <strong class="kb-alert-title">취소 신청</strong>
        </div>
        <div class="contents-area">
          <p>취소사유</p>
          <textarea rows="10" class="kb-form-control" placeholder="취소사유를 입력해 주세요."></textarea>
        </div>
        <div class="footer-area">
          <p class="text text-muted">※ 입소 3영업일 전까지는 취소가 가능하며 이후 취소시에는 연수원 담당자 앞 개별 요청 해주셔야 합니다.</p>
        </div>
      </div>
      <div class="kb-alert-actions">
        <button class="kb-btn-alert" @click="closeCancelPop"><span class="text">닫기</span></button>
        <button class="kb-btn-alert" @click="cancelTrnctAply"><span class="text">취소 신청</span></button>
      </div>
    </div>
  </div>

</template>

<script>

import {
  ACT_CANCEL_TRNCT_PLAY,
  ACT_GET_TRNCT_APLY, ACT_INIT_TRNCT_APLY_MOBILE_INFO, ACT_SET_TRNCT_APLY_MOBILE_INFO,
} from "@/store/modules/trnct/trnct";
import {dateToDateFormat, getItems, getPreviousBusinessDay, isSuccess, lengthCheck} from "@/assets/js/util";
import {useStore} from "vuex";
import {ref, reactive, onMounted,} from 'vue';
import {useRouter} from "vue-router";
import {useAlert} from "@/assets/js/modules/common/alert";

export default {
  name: 'HistoryMobileComponent',
  components: {

  },
  props:{

  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const items = ref([{}]);
    const {showConfirm, showMessage} = useAlert();
    const isCancelPop = ref(false);
    const updateTrnctAply = reactive({
      trnctAplySn : null,
      prsnBrcYn : null,
      aplcntId : null,
      aplcntJbgd: null,
      aplcntNm: null,
      accDeptNm: null,
      aplcntDeptNm : null,
      rsvtAplyDd: null,
      trnctNm: null,
      roomTy: null,
      emerCt: null,
      carNo : null,
      mblTelno: null,
      useRoomCnt : null,
      mlePeopl: null,
      femPeopl: null,
      usePerid: null,
      aplyRsn: null,
      dmndCn: null,
      aplyDtl : [
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        },
        {
          aplcntRel : null, //신청자 관계
          cpnnNm : null, //동반자 명
        }
      ],
      cancelDesc: null,
      stbyYn : 'N'
    })

    const getTrcntAply = () => {
      
      items.value = [];
        store.dispatch(`trnct/${ACT_GET_TRNCT_APLY}`, {
        pageNo: 1,
        pageSize: 3,
      }).then(res => {
        if (lengthCheck(res)) {
          
          items.value = getItems(res);
          
        }
      }).catch(e => {
        console.error(e);
      });
    }

    // eslint-disable-next-line no-unused-vars
    const detailTrcntAply = async (item) => {
      updateTrnctAply.trnctAplySn = item.trnctAplySn;
      updateTrnctAply.prsnBrcYn = item.prsnBrcYn;
      updateTrnctAply.aplcntId = item.aplcntId;
      updateTrnctAply.aplcntJbgd= item.aplcntJbgd;
      updateTrnctAply.aplcntNm= item.aplcntNm;
      updateTrnctAply.accDeptNm= item.accDeptNm;
      updateTrnctAply.aplcntDeptNm= item.aplcntDeptNm;
      updateTrnctAply.rsvtAplyDd= item.rsvtAplyDd;
      updateTrnctAply.trnctNm= item.trnctNm;
      updateTrnctAply.roomTy= item.roomTy;
      updateTrnctAply.room= item.roomTy;
      updateTrnctAply.emerCt= item.emerCt;
      updateTrnctAply.carNo = item.carNo;
      updateTrnctAply.mblTelno= item.mblTelno;
      updateTrnctAply.useRoomCnt = item.useRoomCnt;
      updateTrnctAply.mlePeopl= item.mlePeopl;
      updateTrnctAply.femPeopl= item.femPeopl;
      updateTrnctAply.usePerid= item.usePerid;
      updateTrnctAply.aplyRsn= item.aplyRsn;
      updateTrnctAply.dmndCn= item.dmndCn;
      updateTrnctAply.trnctMstSn = item.trnctMstSn;

      updateTrnctAply.aplyPeopl = item.aplyPeopl;
      updateTrnctAply.stbyYn = item.stbyYn;

      for(let i = 0 ; i < item.aplyDtl.length ;i++){
        updateTrnctAply.aplyDtl[i].cpnnNm = item.aplyDtl[i].cpnnNm;
        updateTrnctAply.aplyDtl[i].aplcntRel = item.aplyDtl[i].aplcntRel;
      }

      store.commit(`trnct/${ACT_INIT_TRNCT_APLY_MOBILE_INFO}`);
      store.commit(`trnct/${ACT_SET_TRNCT_APLY_MOBILE_INFO}`, updateTrnctAply);

      router.push('/daily/apply/detail');
      //return;
      //2주동안 임시로 수정기능을 막는다

    }

    const closeCancelPop = () => {
      isCancelPop.value =false;
    }

    const openCancelPop = (item) => {
      updateTrnctAply.trnctAplySn = item.trnctAplySn;
      updateTrnctAply.stbyYn = item.stbyYn;
      isCancelPop.value = true;
    }

    const cancelTrnctAply = async () => {

      showConfirm({
        text: '취소 하시겠습니까?',
        callback: async () => {
          await store.dispatch(`trnct/${ACT_CANCEL_TRNCT_PLAY}`, updateTrnctAply).then(res => {
            if (isSuccess(res)) {
              isCancelPop.value = false;
              updateTrnctAply.cancelDesc = null;
              getTrcntAply();
            }
          }).catch((e) => {
            console.error(e);
          })

        }
      });


    }


    const alertThreeday = () =>{
      showMessage('3영업일 이내 수정/취소 하실 수 없습니다.');
      return;
    }

    const checkThreeDay = (item) => {
      if(item.rsvtAplyDd != undefined){
        const masTime = new Date(item.rsvtAplyDd);

        // 당일포함 3영업일이므로 days는 2로 지정
        let limitDay = getPreviousBusinessDay(masTime, 2, 'yyyy-MM-dd');
        const todayTime = dateToDateFormat(new Date(),'yyyy-MM-dd') ;

        return todayTime<limitDay;
      }

      // const masTime = new Date(item.rsvtAplyDd);
      // const todayTime = new Date();
      // const diff = masTime - todayTime;
      //
      // const diffDay = Math.floor(diff / (1000*60*60*24));
      // if(diffDay <= 2){
      //   return false;
      // }
      // return true;
    }

    onMounted(() => {
      getTrcntAply();
    });

    return {
      getTrcntAply,
      items,
      detailTrcntAply,
      updateTrnctAply,
      closeCancelPop,
      openCancelPop,
      cancelTrnctAply,
      checkThreeDay,
      isCancelPop,
      alertThreeday
    }
  }
};
</script>
<style>
.title-area{
  width:100%;
  padding-bttom:5px;
  border-bottom: 1px solid #e8e8e8;
  text-align:center;
  margin-bottom:5px;
}

.contents-area{
  width:100%;
  padding:10px;
  background-color: var(--kb-background-brown);
  border-radius: var(--kb-border-radius);
  margin-bottom:5px;
}

.contents-area p{
  padding:5px;
  border-bottom: 1px solid #e8e8e8;
}

textarea{
  background-color: var(--kb-background-brown);
  border-radius: var(--kb-border-radius) !important;
  padding: 5px !important;
}
</style>